<template>
    <b-modal title='Product' id='productsModal' size="lg" hide-footer>
        <b-overlay :show="busySaving">
            <!-- form -->
            <b-form @submit.prevent="handleSubmit">
                <b-row>
                    <b-col>
                        <label class="mb-0" for="">Product Name:</label>
                        <b-form-input v-model="productData.name" size="sm" type="text" required></b-form-input>
                    </b-col>
                    <b-col>
                        <label class="mb-0" for="">Price:</label>
                        <b-form-input v-model.number="productData.price" size="sm" type="number" required step="any"></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col>
                        <label class="mb-0" for="">Stock Keeping Unit:</label>
                        <b-form-input v-model="productData.sku" size="sm" type="text" required></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col>
                        <label class="mb-0" for="">Description:</label>
                        <b-form-textarea v-model="productData.description" size="sm" required></b-form-textarea>
                    </b-col>
                </b-row>
                <div class="modal-footer">
                    <b-button type="submit" variant="primary">Submit</b-button>
                    <button type="button" class="btn btn-secondary" @click="hideModal()">Close</button>
                </div>
            </b-form>
        </b-overlay>
    </b-modal>

</template>

<script>
import { productMethods, productComputed} from "@/state/helpers"
export default {
    props:{
        product_id:{
            default: -1,
            required: true,
        }
    },
    data:() => ({
        productData:{
            id: -1,
            name: '',
            price: '',
            sku: '',
            description: '', // added description field
            group: null, //mens, womens, unisex,
        },
        busySaving: false,
        /*group_types: [
            { value: null, text: 'Please select an option', disabled: true },
            { value: 'mens', text: 'Men' },
            { value: 'womens', text: 'Women' },
            { value: 'unisex', text: 'Unisex' },
        ]*/
    }),
    computed:{
        ...productComputed,
    },
    methods:{
        ...productMethods,
        handleSubmit(){
            this.busySaving = true;
            this.saveProduct(this.productData).then(()=>{
                this.getProducts().then(()=>{
                    this.busySaving = false;
                    this.hideModal();
                    if(this.propertyData.id == -1){
                        // New item saved
                        this.$swal.fire("Saved", "Product saved!", "success");
                    }
                    else{
                        // updated item
                        this.$swal.fire("Saved", `Updated product: ${this.productData.name}`, "success");
                    }
                });
            });
        },
        hideModal() {
            this.$bvModal.hide("productsModal");
        },
    },
    mounted(){
        if(this.product_id != -1){
            let product = this.all_products.find( item => item.id == this.product_id);
            if(product){
                this.productData.id = product.id;
                this.productData.name = product.name;
                this.productData.price = product.price;
                this.productData.sku = product.sku;
                this.productData.description = product.description;
                this.productData.group = product.group;
            }
        }
    },
}
</script>

<style>

</style>